import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Slack",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/slack.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Slack`}</h1>
    <p>{`Manage and reply Crisp conversations from Slack`}</p>
    <hr></hr>
    <p>{`This plugin lets you connect your Crisp chat to Slack. It allows you to receive messages from visitors and other Crisp integrations right in your Slack.`}</p>
    <p>{`Benefits of using Crisp and Slack:`}</p>
    <ul>
      <li parentName="ul">{`Have a large team? No need to create a Crisp account per operator: once linked to Slack, we use user profiles from your Slack team.`}</li>
      <li parentName="ul">{`Coordinate operators: receive chat requests on a common channel. Then, decide who will handle that chat and join the visitor chat channel in a click.`}</li>
      <li parentName="ul">{`Integrate Crisp to your existing workflow and avoid using too many apps.`}</li>
      <li parentName="ul">{`Messages you send and receive on/from Slack with a visitor are still stored in your Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Messages sent to a visitor from a Crisp app are synced to Slack, so that everything gets visible in the same place.`}</li>
      <li parentName="ul">{`Commands: want to resolve the chat from Slack? Enter /resolve. Want to get the user email? Enter /email. Want to change it? Enter /email `}<a parentName="li" {...{
          "href": "mailto:user@gmail.com."
        }}>{`user@gmail.com.`}</a>{` There are many more commands supported.`}</li>
      <li parentName="ul">{`Typing indicator: the visitor still sees when you are typing a message`}</li>
      <li parentName="ul">{`Files: you are still able to send and receive files, right from Slack!`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      